<template>
    <BT-Blade-Item
        bladeName="client-template"
        :bladesData="bladesData"
        navigation="client-templates"
        title="Client Template">
        <template slot-scope="{ item, data }">
            <BT-Field-String
                v-model="item.templateName"
                label="Template Name"
                :isEditing="data.isEditing || data.isNew" />
            
            <BT-Field-Select
                navigation="courier-price-tiers"
                v-model="item.priceTierID"
                itemText="priceTierName"
                label="Price Tier"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Select
                navigation="courier-payment-terms"
                v-model="item.paymentTermsID"
                itemText="paymentTermsName"
                label="Payment Terms"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Toggle-Trigger
                label="Courier Order Confirmation"
                v-model="item.courierRequestApprovalTrigger"
                :isEditing="data.isEditing || data.isNew" />
                    
            <BT-Field-Toggle-Trigger
                label="Route Planning"
                v-model="item.routePlanningTrigger"
                :isEditing="data.isEditing || data.isNew" />
            
            <BT-Field-Toggle-Trigger
                label="Route Movement"
                v-model="item.routeMovementTrigger"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Toggle-Trigger
                label="Courier Order Billing"
                v-model="item.courierOrderBillTrigger"
                :isEditing="data.isEditing || data.isNew" />
                            
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Client-Template',
    props: {
        bladesData: null
    }
}
</script>